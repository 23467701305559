import {CreateMethod, Options} from "../interface";
import AdyenCheckout from "@adyen/adyen-web";

export class Base {
    create: CreateMethod;
    options: Options;

    constructor(checkout: Partial<AdyenCheckout>, create: CreateMethod) {
      this.create = create.bind(checkout);
      this.options = checkout.options;
    }
}