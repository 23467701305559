import * as AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import {
  CreateMethod,
  CreateOptions,
  CreateResponse,
  Options,
} from "./interface";
import {PaymentMethod} from "./constants";
import {Card} from "./components/Card/";
import {Klarna} from "./components/Klarna";
import "@adyen/api-library/lib/src/typings/checkout";

if (process.env.NODE_ENV==='development') {
  require("preact/debug");
}

// @ts-ignore
export default class Checkout extends AdyenCheckout {
    card: Card;
    klarna: Klarna;
    checkoutCreate: CreateMethod;

    constructor(props: Options) {
      super(props);
      this.card = new Card(this, super.create);
      this.klarna = new Klarna(this, super.create);
      this.checkoutCreate = super.create;
    }

    create = (type: string, options?: CreateOptions): CreateResponse  => {
      const paymentMethods = {
        [PaymentMethod.CARD]: this.card.render,
        [PaymentMethod.SCHEME]: this.card.render,
        [PaymentMethod.KLARNA]: this.klarna.render,
      };

      if (type in paymentMethods) {
        if (!options?.storedPaymentMethodId) {
          return paymentMethods[type](options);
        }
      }

      return this.checkoutCreate(type, options);
    };
}

// @ts-ignore
window.AdyenCheckout = Checkout;

