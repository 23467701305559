import AdyenCheckout from '@adyen/adyen-web';
import adyenCheckout, {CreateMethod, CreateOptions, SyncCreateResponse} from "../../interface";
import {Form, PaymentMethod} from "../../constants";
import {Base} from "../../utils/base";

export class Klarna extends Base {
    static nordicCountries = ['SE', 'NO', 'DK', 'FI'];
    type = PaymentMethod.KLARNA;
    paymentMethods: adyenCheckout.PaymentMethod[];
    isNordicCountry: boolean;

    constructor(checkout: Partial<AdyenCheckout>, create: CreateMethod) {
      super(checkout, create);
      this.isNordicCountry = Klarna.nordicCountries.includes(checkout.options.countryCode);
      this.paymentMethods = checkout["paymentMethodsResponse"].paymentMethods;
    }

    getKlarnaPaymentMethod = (): adyenCheckout.PaymentMethod => {
      return this.paymentMethods.filter(pm => pm.type === this.type)[0];
    };


    getOptions = (paymentMethod: adyenCheckout.PaymentMethod, defaultOptions: CreateOptions): any => {
      const filterFields = (field: ICheckout.SubInputDetail): boolean => (
        this.isNordicCountry
          ? [Form.TELEPHONE_NUMBER, Form.SSN].includes(field.key! as Form)
          : [Form.TELEPHONE_NUMBER, Form.GENDER, Form.DATE_OF_BIRTH].includes(field.key! as Form)
      );

      const getSubDetails = (field: ICheckout.SubInputDetail): ICheckout.SubInputDetail => {
        const hasField = [Form.GENDER, Form.SSN].includes(field.key! as Form);
        const isNordicAndHasField= this.isNordicCountry && hasField;
        return isNordicAndHasField ? {...field, optional: false} : field;
      };

      const getDetails = (acc: ICheckout.InputDetail[], detail: ICheckout.InputDetail): ICheckout.InputDetail[] => {
        if (detail.key === 'personalDetails') {
          const fields = detail.details?.filter(filterFields);
          const details = fields?.map(getSubDetails);
          return [...acc, { ...detail, details }];
        }
        return acc;
      };
      const details = (paymentMethod.details as unknown as [])?.reduce(getDetails, []);

      return {
        ...defaultOptions,
        countryCode: this.options.countryCode,
        details,
        visibility: {
          personalDetails: "editable",
        },
      };
    };

    render = (options: CreateOptions): adyenCheckout.CreateResponse => {
      const paymentMethod = this.getKlarnaPaymentMethod();
      const defaultOptions = { ...this.options?.paymentMethodsConfiguration?.klarna, ...options };
      const filteredOption = paymentMethod.details ? this.getOptions(paymentMethod, defaultOptions) : defaultOptions;
      return this.create(this.type, filteredOption) as SyncCreateResponse;
    };
}
