export enum PaymentMethod {
    BCMC = 'bcmc',
    BOLETO = 'boletobancario',
    BCMC_MOBILE_QR = 'bcmc_mobile_QR',
    BCMC_MOBILE_APP = 'bcmc_mobile_app',
    CARD = 'card',
    GOOGLE_PAY = 'paywithgoogle',
    IDEAL = 'ideal',
    KLARNA = 'klarna',
    KLARNA_ACCOUNT = 'klarna_account',
    KLARNA_PAYNOW = 'klarna_paynow',
    SWISH = 'swish',
    APPLE_PAY = 'applepay',
    SCHEME = 'scheme'
}

export enum ComboCard {
    COMBO_CARD_VISA = "electron",
    COMBO_CARD_MASTERCARD = "maestro",
    COMBO_CARD_ELO = "elodebit",
}

export enum CardBrand {
    NULL = 'card',
    VISA = 'visa',
    MASTERCARD = 'mc',
    ELO = 'elo'
}

export enum CardTypes {
    DEBIT = "debit",
    CREDIT = "credit"
}

export enum Events {
    BRAND = 'onBrand',
    CHANGE = 'onChange'
}

export enum Selectors {
    COMBO_CARD_CREDIT = "#combo-card-credit",
    COMBO_CARD_DEBIT = "#combo-card-debit",
    CARD = "adyen-payment-method-card",
    MODAL = "adyen-modal",
    MODAL_CONTENT = ".modal-content"
}

export enum ModalState {
    OPEN = "Open",
    CLOSED = "Closed"
}

export enum GooglePayEnv {
    LIVE = "PRODUCTION",
    TEST = "TEST"
}

export enum Form {
    TELEPHONE_NUMBER = 'telephoneNumber',
    SSN = 'socialSecurityNumber',
    GENDER = 'gender',
    DATE_OF_BIRTH = 'dateOfBirth'
}