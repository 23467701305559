import {InstallmentAttributes, InstallmentCode} from '../../../interface';

type creditCard = { id: number, name: string, code: string };

const creditCards: creditCard[] = [
  { id: 1, name: 'Amex', code: 'amex' },
  { id: 2, name: 'ELO', code: 'elo' },
  { id: 4, name: 'HiperCard', code: 'hiper' },
  { id: 4, name: 'HiperCard', code: 'hipercard' },
  { id: 8, name: 'Mastercard', code: 'mc' },
  { id: 16, name: 'Visa', code: 'visa' },
];

const isAllowedCreditCard: (arg0: creditCard, arg1: number) => boolean = (creditCard, creditCardSum) => (creditCardSum & creditCard.id) !== 0;
const checkAmount: (arg0: number, arg1: number) => boolean = (amount, amountRange) => amount >= amountRange;

export const decodeInstallments = (installmentsOptions: InstallmentCode[], amountValue: number): InstallmentAttributes => {
  const createInstallments = (acc: InstallmentAttributes, item: InstallmentCode): InstallmentAttributes  => {
    const [amountRange, numberOfInstallments, creditCardSum] = item;
    const filteredCreditCards = creditCards.filter(card => isAllowedCreditCard(card, creditCardSum));
    const isValidAmount = checkAmount(amountValue, amountRange);
    if (isValidAmount) {
      filteredCreditCards.forEach(creditCard => {
        if (!acc[creditCard.code]) {
          acc[creditCard.code] = { values: [] };
        }
        acc[creditCard.code] = { values: [...acc[creditCard.code].values, numberOfInstallments].sort((a, b) => a- b) };
      });
    }
    return acc;
  };

  return installmentsOptions.reduce(createInstallments, {});
};