import AdyenCheckout from '@adyen/adyen-web';
import { PaymentMethod } from "../../constants";
import { CreateMethod, CreateOptions, SyncCreateResponse } from "../../interface";
import { Base } from "../../utils/base";
import { decodeInstallments } from "./Installments";

export class Card extends Base {
    isBrazil: boolean;

    constructor(checkout: Partial<AdyenCheckout>, create: CreateMethod) {
      super(checkout, create);
      this.isBrazil = this.options.countryCode === 'BR' && this.options.amount.currency === 'BRL';
    }

    render = (options: CreateOptions): SyncCreateResponse<unknown> => {
      const mergedOptions = {
        ...this.options.paymentMethodsConfiguration?.card,
        ...options,
      };

      const { installments, ...rest } = mergedOptions;
      const installmentOptions = this.isBrazil && installments && decodeInstallments(installments, this.options.amount.value);
      const cardOptions = { ...rest, ...(installmentOptions && { installmentOptions }) };
      const response = this.create(PaymentMethod.CARD, cardOptions) as SyncCreateResponse;
      return response;
    };
}
